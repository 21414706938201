<template>
  <div class="p-1">

    <p class="d-flex justify-content-center saj-header">
      {{ isUpdate ? "Update Employee's Head of Section" : $t("Assign Employee") }}
    </p>
    <b-form-group
      class="saj-title mt-1"
      :label="$t('Choose Employee Number / Name')"
    >
      <b-button
        v-model="buttonEmployee"
        variant="primary"
        class="mt-1"
        style=""
        @click="isEmployeeID = !isEmployeeID, isEmployeeName = !isEmployeeName"
      >
        <span class="saj-button d-md-none d-lg-block"> {{ isEmployeeID === true ? $t("Employee No") : $t("Employee Name") }} </span>
      </b-button>
      <!-- Employee ID or Employee Name Button End -->
      <div
        v-if="!isEmployeeName"
      >
        <!-- ID -->
        <b-form-group
          class="saj-title mt-1"
          :label="$t('Employee Number')"
        >
          <!-- <validation-provider
                  #default="{ errors }"
                  :name="$t('Employee Id')"
                  rules="required"
                > -->
          <v-select
            v-model="selectedEmp"
            class="saj-title select-size-md"
            :selectable="(option) => !selectedList.includes(option.value)"
            style="background: white"
            label="empNo"
            :placeholder="$t('Choose Name')"
            :disabled="isLoading"
            :options="nameList"
            :value="nameList"
            :close-on-select="true"
            :loading="isLoading"
            @input="selectedNumber()"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          <!-- </validation-provider> -->
          <b-form-input
            v-model="selectedEmp.text"
            :placeholder="$t('Employee Name')"
            class="saj-text mt-1"
            disabled
          />
        </b-form-group>
      </div>

      <div v-if="isEmployeeName">
        <!-- Name -->
        <b-form-group
          class="saj-title mt-1"
          :label="$t('Employee Name')"
        >
          <!-- <validation-provider
                  #default="{ errors }"
                  :name="$t('Employee Name')"
                  rules="required"
                > -->
          <v-select
            v-model="selectedEmp"
            class="saj-title select-size-md"
            :selectable="(option) => !selectedList.includes(option.value)"
            style="background: white"
            label="text"
            :placeholder="$t('Choose Name')"
            :disabled="isLoading"
            :options="nameList"
            :value="nameList"
            :close-on-select="true"
            :loading="isLoading"
            @input="selectedNumber()"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          <!-- </validation-provider> -->
          <b-form-input
            v-model="selectedEmp.empNo"
            :placeholder="$t('Employee Number')"
            class="saj-text mt-1"
            disabled
          />
        </b-form-group>

      </div>
    </b-form-group>
    <div
      class="d-flex justify-content-center"
      style=""
    >
      <button
        class="btn m-1 saj-button"
        aria-label="submit modal"
        :disabled="selectedEmp.empNo == null || selectedEmp == null"
        style="

                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
        @click="assignEmployee()"
      >
        {{ $t('Save') }}
      </button>
      <button
        class="btn btn-close m-1 saj-button"
        aria-label="Close modal"
        style="

                  color: white;
                  background: #ff0000;
                "
        @click="close()"
      >
        {{ $t('Back') }}
      </button>
    </div>

  </div>
</template>
<script>
import {
  VBTooltip,
  BFormGroup,
  BFormInput,
  BButton,

} from "bootstrap-vue"
// import SAJToast from '@/component/saj-toastification.vue'
import vSelect from 'vue-select'
// import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {

    vSelect,
    BFormGroup,
    BFormInput,
    BButton,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {

    isUpdate: {
      type: Boolean,
      default: false,
    },
    deptId: {
      type: Number,
      default: null,
    },
    subId: {
      type: Number,
      default: null,
    },
    sectionId: {
      type: Number,
      default: null,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {

      allSelectedName: [],
      nameList: [{
        value: null,
        empNo: null,
        text: null,
      }],
      selectedEmp: [{
        value: null,
        text: null,
        empNo: null,
      }],
      isEmployeeName: false,
      isEmployeeID: false,
      buttonEmployee: null,
      selectedSubReg: null,
      subList: [],
      isLoading: false,
      selectedList: [],
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    ...mapGetters(['roles']),
  },
  mounted(){
    this.getSubsidiary()

    if (this.isUpdate){
      this.getHeadSection()
    } else {
      this.getEmployeeName()
    }
  },
  methods: {

    getSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allbusinessUnit = response.data.data.subsidiaries
        // this.allbusinessUnit.forEach(b => {
        //   this.businessUnitList.push({
        //     text: b.subsidiary_name,
        //     value: b.id,

        //   })
        // })

        this.subList = this.allbusinessUnit.map(b => ({
          text: b.subsidiary_name,
          value: b.id,
        }))
      })
    },

    getEmployeeName() {
      // const params = new FormData()
      // params.append("subsidiary_id", this.id)
      this.nameList = []
      this.isLoading = true
      const params = new URLSearchParams()

      params.append('subsidiary_id', this.subId)
      params.append('department_id', this.deptId)
      params.append('status', 'active')

      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesName`, { params }).then(response => {
        const allName = response.data.data

        this.nameList = allName.map(item => ({
          value: item.employee_id,
          empNo: item.employee_number,
          text: item.full_name,
        }))

        const selected = allName.filter(x => x.head_of_section_assigned !== "")

        this.selectedList = selected.map(x => Number(x.employee_id))

        this.isLoading = false
      })
    },

    getHeadSection(){
      const params = new URLSearchParams()

      params.append('subsidiary_id', this.subId)
      params.append('department_id', this.deptId)
      this.$axios.get(`${this.$baseUrl}/head_of_section/get_all_head_of_section`, { params }).then(response => {
        const sections = response.data.data

        // this.sectionList = sections

        this.nameList = sections.map(item => ({
          value: item.employee.id,
          empNo: item.employee.employee_number,
          text: item.employee.full_name,
        }))
        this.isLoading = false
      })
    },

    selectedNumber(){
      // console.log('items', this.items)
      if (this.selectedEmp === null){
        // eslint-disable-next-line no-param-reassign
        this.selectedEmp = [{ text: null, value: null, empNo: null }]
        // return this.selectedEmp
      }

      return this.selectedEmp
    },

    assignEmployee() {
      const data = new FormData()

      data.append('head_of_section_id', this.sectionId)
      data.append('employee_id', this.selectedEmp.value)

      this.$axios.post(`${this.$baseUrl}/head_of_section/add_employee`, data).then(() => {
        this.$emit('reload')
        this.$emit('close')
        // console.log('res', res)
         this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t("Succesfully assign employee!")}`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    titleColor: "#000",
                  },
                },
                {
                  position: "top-right",
                  type: "info",
                }
              );
      }).catch(err => {
        const msg = err.response.data.data
        console.log('msg', msg)
        let txt = null

        if (msg.bi_msg !== undefined){
          txt = this.isEnglish ? msg.bi_msg : msg.bm_msg
        } else {
          txt = this.isEnglish ? 'Staff is Head of Section' : 'Staff telah dilantik sebagai Ketua Seksyen'
        }
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: txt,
              icon: "XIcon",
              variant: "danger",
              iconBg: '#e80202',
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.$emit("close")
      })
    },

    updateEmployee(){
      const data = new FormData()

      data.append('head_of_section_id', this.sectionId)
      data.append('employee_id', this.selectedEmp.value)

      this.$axios.post(`${this.$baseUrl}/head_of_section/update_employee`, data).then(() => {
        this.$emit('reload')
        this.$emit('close')
      })
    },

    close(){
      this.$emit('close')
    },

  },
}
</script>
