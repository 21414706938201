<template>
  <div>

    <b-card
      no-body
      class="saj-title mr-1 p-1"
      style=""
    >
      <div
        class="saj-title pl-1 pt-1 p-1"
        style=""
      >
        {{ $t('Head of Section Name') }} : {{ section.name }}
      </div>

      <div class="pl-1 pt-1 ">
        <span class="saj-title">
          {{ $t("Search Filter") }}
          <!-- {{ approveList }} -->
        </span>
      </div>
      <div class="row align-items-end mb-1 pl-1 pt-1 pr-2">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Work Location") }}:</label>
          <v-select
            v-model="selectedWork"
            class="saj-text select-size-sm"
            style="background: white"
            :placeholder="$t('Choose Work Location')"
            :options="WorkList"
            :reduce="work => work.value"
            label="text"
          />
        </b-col>
        <!-- </template> -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <label class="saj-text">{{ $t("Employee Number") }}:</label>
          <!-- <b-form-select
              v-model="selectedEmployeeID"
              :options="empIDList"
              :value="empIDList"
              size="sm"
              class="saj-text"
            /> -->
          <b-form-input
            v-model="selectedEmployeeID"
            :placeholder="$t('Search Employee Number')"
            size="sm"
            class="saj-text"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Search Name") }}:</label>
          <b-form-input
            id="icons-search"
            v-model="searchname"
            :placeholder="$t('Search name')"
            size="sm"
          />
        </b-col>
        <div class="d-flex mt-1">
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="d-flex justify-content-center"
              style=""
              @click="getEmployee()"
            >

              <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="saj-title d-flex justify-content-center"
              block
              style="
          "
              @click="clearButton()"
            >
              <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
            </b-button>
          </b-col>
        </div>
      </div>

      <b-card-body>
        <!-- <div class="d-flex justify-content-end">
          <b-form-input
            v-model="searchEmp"
            style="width: 250px;"
            :placeholder="$t('Search') + '...'"
            size="sm"
            class="d-flex align-items-end"
          />
         </div> -->
        <div class="d-flex justify-content-end mt-2">
          <b-button
            md="4"
            class="p-1 saj-button ml-1"
            style=""
            :href="`${publicPath}assets/head_of_section_template.xlsx`"
            download
            variant="primary"
          >

            {{ $t('CSV Template') }}
          </b-button>
          <b-button
            v-b-modal.csv-upload
            md="4"
            class="p-1 saj-button ml-1"
            style=""
            variant="primary"
          >

            {{ $t('Import CSV') }}
          </b-button>
          <b-button
            v-b-modal.assign-employee
            md="4"
            class="p-1 saj-button ml-1"
            style=""
            variant="primary"
          >

            {{ $t('Assign Employee') }}
          </b-button>
        </div>
        <b-table
          show-empty
          :items="empList"
          :fields="fields"
          :filter="searchEmp"
          responsive=""
          bordered
          class="mt-1 mb-1 saj-subtitle"
        >
          <!-- A custom formatted column -->
          <template #head()="data">
            <span
              class="saj-title"
              style=""
            >{{ $t(data.label) }}</span>
          </template>

          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>

          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(employee_number)="data">
            {{ data.item.employee_number }}
          </template>
          <template #cell(name)="data">
            {{ data.item.employee_name }}
          </template>
          <template #cell(work)="data">
            {{ data.item.branch }}
          </template>
          <template #cell(action)="data">
            <div
              class="saj-text"
              style=""
            >
              <div>
                <feather-icon
                  v-b-modal.delete-employee
                  v-b-tooltip.hover.bottom="$t('Remove')"
                  icon="XIcon"
                  color="red"
                  size="25"
                  @click="selectedEmp = data.item.employee_id"
                />
                <!-- <feather-icon
                  v-b-modal.update-employee
                  v-b-tooltip.hover.bottom="$t('Edit')"
                  icon="EditIcon"
                  size="22"
                  style="color: orange;"
                  class="ml-1"
                /> -->
              </div>
            </div>
          </template>
        </b-table>
      </b-card-body>
      <b-row v-if="empList.length > 0">
        <b-col>
          <span
            class="saj-text"
            align="start"
          >
            {{ $t("Showing") }} {{ rows > perPage*currentPage ? perPage*currentPage : rows }} {{ $t("from") }} {{ rows }} {{ $t("entries") }}
            <!-- {{ $t("Showing") }} 1 {{ $t("to") }} 2 {{ $t("of") }} 3 {{ $t("entries") }} -->
          </span>
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            pills
            class="saj-text mb-1 justify-content-end"
          />
        </b-col>
      </b-row>

      <b-modal
        id="assign-employee"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
        :no-close-on-backdrop="true"
        size="lg"
      >
        <assign-emp
          :dept-id="section.department_id"
          :sub-id="section.subsidiary_id"
          :section-id="sectionId"
          @close="$bvModal.hide('assign-employee')"
          @reload="getEmployee()"
        />
      </b-modal>

      <b-modal
        id="delete-employee"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
        :no-close-on-backdrop="true"
        size="lg"
        class="p-1"
      >
        <alertModal
          title="Remove employee from Head of Section?"
          confirm-btn="Save"
          @confirm="deleteEmployee()"
          @cancel="$bvModal.hide('delete-employee')"
        />
      </b-modal>

      <b-modal
        id="update-employee"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
        :no-close-on-backdrop="true"
        size="lg"
      >
        <assign-emp
          :dept-id="section.department_id"
          :sub-id="section.subsidiary_id"
          :section-id="sectionId"
          :is-update="true"
          @close="$bvModal.hide('update-employee')"
          @reload="getEmployee()"
        />
      </b-modal>

      <b-modal
        id="csv-upload"
        :hide-header="true"
        :hide-footer="true"
        :centered="true"
        size="lg"
        :no-close-on-backdrop="true"
      >
        <div class="p-1">
          <h3 class="d-flex justify-content-center">
            {{ $t("Employee Bulk Assign") }}
          </h3>
          <p class="saj-subtitle">
            {{ $t("Upload CSV file") }}
          </p>
          <b-form-file
            v-model="excelFile"
            type="file"
            class="saj-text"
            :browse-text="$t('Choose File')"
            :placeholder="$t('No file choosen')"
            style="width:100%; text-align: start; "
            accept=".xlsx"
          />
          <div class="d-flex justify-content-center">
            <button
              class="btn m-1 saj-button"
              style="
                          color: white;
                          background: #0b103c !important;
                          border-color: #0b103c !important;
                        "
              :disabled="excelFile === null"
              @click="importExcel()"
            >
              {{ $t('Save') }}
            </button>
            <button
              class="btn btn-close m-1 saj-button"
              style="
                  color: white;
                  background: #ff0000;
                "
              @click="$bvModal.hide('csv-upload'), selectedOpt = null"
            >
              {{ $t('Cancel') }}
            </button>
          </div>
        </div>
      </b-modal>

    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BTable,
  // BFormInput,
  BButton,
  VBTooltip,
  BModal,
  BFormFile,
  BCol,
  BFormInput,
  BRow,
  BPagination,
} from "bootstrap-vue"
import { mapGetters } from 'vuex'
import moment from "moment"
import SAJToast from "@/component/saj-toastification.vue"
import alertModal from "@/pages/alert-modal.vue"
import vSelect from 'vue-select'
import assignEmp from './assigned_modal.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BTable,
    // BFormInput,
    BButton,
    BModal,
    assignEmp,
    BFormFile,
    alertModal,
    BCol,
    BFormInput,
    vSelect,
    BRow,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      searchEmp: null,
      projectViewEmployee: [],
      empList: [],
      //   memberData: null,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'employee_number', label: 'Employee No.', thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'name', label: 'Employee Name', thClass: 'text-center' },
        { key: 'work', label: 'Work Location', thClass: 'text-center' },
        {
          key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center',
        },

      ],
      assignee: null,
      subsidairy: 1,
      sectionId: null,
      section: null,
      selectedEmp: null,
      excelFile: null,
      selectedWork: null,
      selectedEmployeeID: null,
      searchname: null,
      subId: null,
      WorkList: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
    }
  },
  computed: {
    ...mapGetters(['userDetails']),
    // isEnglish() {
    //   return this.$i18n.locale === 'en'
    // },
  },
  mounted() {
    this.getEmployee()
  },
  beforeMount() {
    this.sectionId = this.$route.params.userId
  },
  methods: {
    DateChanged(date) {
      const newDay = moment(date).format("D-MM-yyyy")
      return newDay
    },

    clearButton(){
      this.selectedWork = null
      this.selectedEmployeeID = null
      this.searchname = null

      this.getEmployee()
    },
    getWorkLocation(){
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${this.subId}`).then(res => {
        const work = res.data.data
        this.WorkList = work.map(x => ({
          text: x.name,
          value: x.id,
        }))
      })
    },

    getEmployee(){
      const params = new URLSearchParams()

      params.append('head_of_section_id', this.sectionId)

      if (this.selectedWork !== null){
        params.append('branch_id', this.selectedWork)
      }

      if (this.selectedEmployeeID !== null){
        params.append('employee_number', this.selectedEmployeeID)
      }

      if (this.searchname !== null){
        params.append('employee_name', this.searchname)
      }

      this.$axios.get(`${this.$baseUrl}/head_of_section/get_employees`, { params }).then(response => {
        const info = response.data.data

        if (info.employee_assigned !== undefined){
          this.empList = info.employee_assigned
        } else {
          this.empList = []
        }

        this.section = info.head_of_section
        this.subId = info.head_of_section.subsidiary_id

        this.getWorkLocation()

        this.rows = this.empList.length

        // console.log('name 1', info.employee_assigned)
      })
    },

    deleteEmployee(){
      const data = new FormData()

      data.append('employee_id', this.selectedEmp)

      this.$axios.post(`${this.$baseUrl}/head_of_section/remove_assignee`, data).then(() => {
        this.getEmployee()
        this.$bvModal.hide("delete-employee")
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Successfully deleted')}!`,
              icon: "Trash2Icon",
              iconBg: '#e80202',
              variant: "success",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )

        // console.log('res', info)
      })
    },

    importExcel(){
      const data = new FormData()

      data.append('file', this.excelFile)
      data.append('head_of_section_id', this.sectionId)

      this.$axios.post(`${this.$baseUrl}/head_of_section/import_employee`, data).then(() => {
        this.selectedOpt = null
        this.$bvModal.hide('csv-upload')
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Your Excel file is currently being processed')}!`,
              icon: "CheckCircleIcon",
              variant: "info",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        // console.log('res', res)
      }).catch(() => {
      })

      // console.log('import')
    },
  },

}
</script>
